<template>
  <el-dialog
    top="50px"
    width="400px"
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('dealer_anomaly.fileList') }}
    </div>
    <div v-if="fileList.length">
      <el-row
        v-for="(file, index) in fileList"
        :key="index"
      >
        <el-button
          type="text"
          style="font-size:14px;"
          icon="el-icon-document"
          @click="downloadFile(file)"
        >
          {{ file.name }}
        </el-button>
      </el-row>
    </div>
    <div v-else>
      无数据
    </div>
    <div slot="footer">
      <el-button @click="currentShowFlag = false">
        {{ $t('operation.close') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  download: '/system/attachment/download'
}
export default {
  name: 'FileList',
  props: {
    showFlag: { type: Boolean, default: false },
    fileList: { type: Array, default: () => { return [] } }
  },
  data () {
    return {
      formModel: {}
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    }
  },
  methods: {
    handleDialogOpen () {},
    downloadFile (file) {
      this.$utils.fileSaveAs(BASEURL.download, { id: file.id })
    },
    handleDialogClosed () {}
  }
}
</script>
