<template>
  <div style="width:100%;height:100%;">
    <ag-grid
      ref="tsdCheckGrid"
      pagination
      style="width:100%;height:100%;"
      edit-type="fullRow"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-if="isPreset"
            v-permission="'thirdParty_preset:generate'"
            type="primary"
            @click="handleGenerate"
          >
            {{ $t('operation.generate') }}
          </el-button>
          <el-button
            v-if="isPreset"
            v-permission="'thirdParty_preset:export'"
            type="primary"
            @click="handleExport"
          >
            {{ $t('operation.export') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <simple-upload
      ref="uploader"
      :show-confirm="true"
      :file-list="fileList"
      url="/system/attachment/upload"
      :show-flag.sync="isShowUploader"
      @remove="handleRemove"
      @confirm="handleUploadConfirm"
      @on-success="handleUploadSuccess"
    />
    <file-list
      :file-list="fileList"
      :show-flag.sync="isShowFileList"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import fileList from './fileList'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/exceptionKpi/list',
  list2: '/jlr/tt/exceptionKpiTemp/list',
  get: '/jlr/tt/exceptionKpi/get',
  update: '/jlr/tt/exceptionKpi/update',
  office: '/system/office/list',
  export: '/jlr/tt/exceptionKpiTemp/export'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleSaveClick', 'handleAjustEntriesClick'],
  computed: { detailData () { return this.params.context.componentParent.$parent.detailData } },
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-refresh-left" @click="handleDataSearch"/>
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_reportReview.adjustEntries')">
        <el-button type="text" style="font-size:14px;" v-permission="'thirdParty_reportReview:ajustEntries'"
          icon="el-icon-s-open" :disabled="detailData.isSubmit==='Y' || params.data.riskLevel !== 'tt_risk_level_1'"
          @click="handleAjustEntriesClick(detailData)" />
      </el-tooltip>
    </div>`
})

/** 自定义单元格按钮 */
const AddDocsButton = Vue.extend({
  inject: ['handleDataSearch', 'handleImportClick', 'showFileList'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('dealer_anomaly.addDocs')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-upload" @click="handleImportClick(params)"
          :disabled="params.context.componentParent.$parent.viewFlag" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('dealer_anomaly.viewAttachments')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-tickets" :disabled="!(params.data.attachmentCount && params.data.attachmentCount>=1)"
          @click="showFileList(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'FluctuationCheck',
  components: { fileList },
  mixins: [pageMixins],
  props: {
    detailData: { type: Object },
    viewFlag: { type: Boolean, default: false },
    isPreset: { type: Boolean, default: false },
    isThirdParty: { type: Boolean, default: false }
  },
  data () {
    return {
      searchModel: this.isPreset ? { 'head.period': this.$moment().subtract(1, 'months').format('YYYYMM') } : {},
      tableData: [],
      fileList: [],
      currentRowNode: {},
      uploaded: false,
      gridOptions: {},
      isShowFileList: false,
      isShowUploader: false,
      frameworkComponents: {},
      dealerList: [],
      dealerLoading: false
    }
  },
  inject: ['handleAjustEntriesClick', 'handleGenerate'],
  provide () {
    return {
      showFileList: this.showFileList,
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleImportClick: this.handleImportClick,
      handleAjustEntriesClick: this.handleAjustEntriesClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'kpi.name',
          label: this.$t('dealer_anomaly.indicatorName'),
          type: 'Input',
          col: { xs: 4, sm: 4, md: 4 },
          component: { clearable: true }
        },
        {
          prop: 'dealer',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.dealer'),
          hidden: !this.isPreset,
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        {
          prop: 'head.period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          hidden: !this.isPreset,
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          slotName: 'tableOperation',
          col: this.isPreset ? { xs: 12, sm: 12, md: 12 } : { xs: 16, sm: 16, md: 16 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_anomaly.anomalyIndicatorSummary'),
          children: [
            {
              headerName: this.$t('dealer_anomaly.indicatorName'),
              field: 'kpi.name',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_anomaly.riskLevel'),
              field: 'riskLevel',
              suppressSizeToFit: true,
              cellStyle: { lineHeight: '22px' },
              cellRenderer: params => {
                if (!params.data.riskLevel) return ''
                const color = params.data.riskLevel === 'tt_risk_level_1' ? 'red' : 'green'
                return `<div style="width:22px;height:22px;margin:auto;border-radius:22px;background:${color};" />`
              },
              width: 100
            },
            {
              headerName: this.$t('dealer_anomaly.kpiFormula'),
              field: 'kpi.calculationFormula',
              minWidth: 360
            },
            {
              headerName: this.$t('dealer_anomaly.upperThreshold'),
              field: 'upperThreshold',
              valueFormatter: params => {
                const val = params.data.manualUpperLimit || params.data.upperLimit
                return this.$utils.numberFormat(val, { thousand: true, toFixed: 4, showZero: true })
              },
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_anomaly.lowerThreshold'),
              field: 'lowerThreshold',
              valueFormatter: params => {
                const val = params.data.manualLowerLimit || params.data.lowerLimit
                return this.$utils.numberFormat(val, { thousand: true, toFixed: 4, showZero: true })
              },
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_anomaly.currentData'),
              field: 'endBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 4, showZero: true }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_anomaly.dealerReport'),
          children: [
            {
              headerName: this.$t('dealer_finance.dealerName'),
              field: 'head.dealer.name',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.dealerCode'),
              field: 'head.dealer.code',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_anomaly.dealerExplain'),
              field: 'dealerExplain',
              editable: params => (!this.isThirdParty && params.data.riskLevel === 'tt_risk_level_1') && !this.viewFlag,
              cellEditorParams: {
                rules: { required: true, message: this.$t('validate.isRequired') }
              },
              minWidth: 300
            },
            {
              headerName: this.$t('dealer_anomaly.evidenceDocs'),
              suppressSizeToFit: true,
              cellRenderer: 'AddDocsButton',
              width: 120
            },
            {
              headerName: this.$t('thirdParty_reportReview.checkReview'),
              field: 'reviewResults',
              hide: !this.isThirdParty,
              editable: params => params.data.riskLevel === 'tt_risk_level_1' && !this.viewFlag,
              cellEditorParams: params => {
                return {
                  rules: {
                    required: params.data.riskLevel === 'tt_risk_level_1',
                    message: this.$t('validate.isRequired')
                  }
                }
              },
              minWidth: 300
            }
          ]
        },
        {
          headerName: this.$t('field.operation'),
          width: 110,
          pinned: 'right',
          hide: this.viewFlag,
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation, AddDocsButton: AddDocsButton }
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      params.filter.push({ left: 'type', operate: '=', right: 'mt_group_kpi_type_2' })
      if (this.detailData && this.detailData.id) {
        params.filter.push({ left: 'head.id', operate: '=', right: this.detailData.id })
      }
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      params.page = { orderBy: 'kpi.code' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(this.isPreset ? BASEURL.list2 : BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExport () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      params.page = { orderBy: 'kpi.code' }
      this.$axios
        .post(BASEURL.export, params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    showFileList (rowData) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.get, { id: rowData.id })
        .then(resp => {
          const attachmentMappings = resp.data.attachmentMappings || []
          this.fileList = attachmentMappings.map(ele => {
            if (ele.attachment) {
              return {
                id: ele.attachment.id,
                url: ele.attachment.savePath,
                name: `${ele.attachment.fileName}.${ele.attachment.fileType}`
              }
            }
          })
          this.isShowFileList = true
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.dealerList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    handleSaveClick (row) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      // 获取附件数据
      this.$axios
        .post(BASEURL.get, { id: row.data.id })
        .then(resp => {
          row.data.attachmentMappings = resp.data.attachmentMappings
          this.save(row)
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    save (row) {
      this.$refs.tsdCheckGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
          this.$axios
            .post(BASEURL.update, row.data)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              // 本页希望保留 filter 数据，保存后不刷新数据
              // this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleImportClick (params) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.get, { id: params.data.id })
        .then(resp => {
          const attachmentMappings = resp.data.attachmentMappings || []
          this.fileList = attachmentMappings.map(ele => {
            if (ele.attachment) {
              return {
                id: ele.attachment.id,
                url: ele.attachment.savePath,
                name: `${ele.attachment.fileName}.${ele.attachment.fileType}`
              }
            }
          })
          this.currentRowNode = params.node
          this.uploaded = false
          this.isShowUploader = true
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleRemove (file, fileList) {
      let data = this.currentRowNode.data
      data.attachmentMappings = []
      fileList.forEach(ele => {
        data.attachmentMappings.push({ attachment: { id: ele.id || ele.response.data.id } })
      })
      this.uploaded = true
    },
    handleUploadSuccess (response, file, fileList) {
      let data = this.currentRowNode.data
      data.attachmentMappings = []
      fileList.forEach(ele => {
        data.attachmentMappings.push({ attachment: { id: ele.id || ele.response.data.id } })
      })
      this.uploaded = true
    },
    handleUploadConfirm () {
      // 没有上传新文件或删除上传文件的操作时就不要保存
      if (this.uploaded) {
        this.save(this.currentRowNode)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
