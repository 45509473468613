<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>

<template>
  <div class="page__wrapper">
    <el-tabs
      v-model="activeName"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        :label="$t('dealer_anomaly.fluctuationCheck')"
        name="fluctuationCheck"
      >
        <fluctuation-check
          ref="fluctuationCheck"
          :detail-data="{}"
          :view-flag="true"
          :is-preset="true"
          :is-third-party="true"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('dealer_anomaly.thresholdCheck')"
        name="thresholdCheck"
      >
        <threshold-check
          ref="thresholdCheck"
          :view-flag="true"
          :detail-data="{}"
          :is-preset="true"
          :is-third-party="true"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('dealer_anomaly.logicalCheck')"
        name="logicalCheck"
      >
        <logical-check
          ref="logicalCheck"
          :view-flag="true"
          :detail-data="{}"
          :is-preset="true"
          :is-third-party="true"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import fluctuationCheck from '../../dealer/anomaly/fluctuationCheck'
import thresholdCheck from '../../dealer/anomaly/thresholdCheck'
import logicalCheck from '../../dealer/anomaly/logicalCheck'

const BASEURL = {
  execute: '/jlr/tt/exceptionKpiTemp/execute',
  export: '/jlr/tt/exceptionKpiTemp/export'
}
export default {
  components: { fluctuationCheck, thresholdCheck, logicalCheck },
  data () {
    return {
      activeName: 'fluctuationCheck'
    }
  },
  provide () {
    return {
      handleGenerate: this.handleGenerate,
      handleExport: this.handleExport,
      handleAjustEntriesClick: this.handleAjustEntriesClick
    }
  },
  mounted () {
    this.activeName = 'fluctuationCheck'
    this.$nextTick(() => {
      this.$refs.fluctuationCheck.handleDataSearch()
    })
  },
  methods: {
    handleTabClick (tab) {
      this.$refs[tab.name].handleDataSearch()
    },
    handleAjustEntriesClick () {},
    handleGenerate () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.execute, {})
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.generateSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
    // handleExport () {
    //   const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
    //   const params = { page: { orderBy: 'kpi.code' } }
    //   this.$axios
    //     .post(BASEURL.export, params)
    //     .then(resp => {
    //       this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
    //     })
    //     .finally(() => {
    //       loadingFlag.close()
    //     })
    // }
  }

}
</script>

<style>

</style>
